<template>
    <div class="home">
        <div class="Hotop">
            <div class="Hotop-did">
                <div class="did-feid">
                    <div>项目名称: <span>{{fitodat.project_name}}</span></div>
                    <div>项目地址: <span>{{fitodat.project_address}}</span></div>
                    <div>设备数量: <span>{{fitodat.facility_number}}</span></div>
                </div>
            </div>
            <div class="Hotop-did">
                <div class="did-feid">
                    <div>最高瞬时流量: <span>{{fitodat.max_instantaneous_flow}}</span></div>
                    <div>最低瞬时流量: <span>{{fitodat.min_instantaneous_flow}}</span></div>
                    <div>平均瞬时流量: <span>{{fitodat.avg_instantaneous_flow}}</span></div>
                </div>
            </div>
            <div class="Hotop-did">
                <div class="did-feid">
                    <div>最高功率: <span>{{fitodat.max_thermal_power}}</span></div>
                    <div>最低功率: <span>{{fitodat.min_thermal_power}}</span></div>
                    <div>平均功率: <span>{{fitodat.avg_thermal_power}}</span></div>
                </div>
            </div>
            <div class="Hotop-dis">
                <div class="did-feid">
                    <div>最高供水温度: <span>{{fitodat.max_water_supply_temperature}}</span></div>
                    <div>最低供水温度: <span>{{fitodat.min_water_supply_temperature}}</span></div>
                    <div>平均供水温度: <span>{{fitodat.avg_water_supply_temperature}}</span></div>
                    <div>最高回水温度: <span>{{fitodat.max_return_water_temperature}}</span></div>
                    <div>最低回水温度: <span>{{fitodat.min_return_water_temperature}}</span></div>
                    <div>平均回水温度: <span>{{fitodat.avg_return_water_temperature}}</span></div>
                </div>
            </div>
        </div>
        <div class="Hometo">
            <div class="metolef">
                <div class="rei-text">近七天表具数量变化</div>
                <div id="myChart" :style="{width:'100%',height:'600px'}"></div>
            </div>
            <div class="metorei">
                <div class="rei-text">告警信息</div>
                <el-card>
                <el-table :data="tableData" size=mini height='500' tooltip-effect="dark"
                highlight-current-row :header-cell-style="{'text-align':'center'}"
                :cell-style="{'text-align':'center'}" element-loading-text="拼命加载中">
                    <el-table-column property="id" label="id" />
                    <el-table-column property="meter_id" label="表号"/>
                    <el-table-column property="warn_type" label="告警类型"/>
                    <el-table-column property="warn_time" label="告警时间"/>
                </el-table>
                </el-card>
            </div>
        </div>
    </div>
</template>
<script>
import { statisticsget, calorimeterWarning} from '../api/home'
import Cookies from 'js-cookie'
export default {
    data() {
        return {
            fitodat:{
                project_name: null,
                project_address: null,
                facility_number: null,
                max_instantaneous_flow: null,
                min_instantaneous_flow: null,
                avg_instantaneous_flow: null,
                max_thermal_power: null,
                min_thermal_power: null,
                avg_thermal_power: null,
                max_water_supply_temperature: null,
                min_water_supply_temperature: null,
                avg_water_supply_temperature: null,
                max_return_water_temperature: null,
                min_return_water_temperature: null,
                avg_return_water_temperature: null,
            },
            aa: {
                token: null
            },
            // 告警信息
            tableData: [],
            // 折线图
            myCdatData:[],
            thCdatData:[]
        }
    },
    created(){
        this.tablData()
    },
    mounted() {
        this.tasData()
        
        
    },
methods:{
    // 统计数据
    tasData() {
        this.aa.token = Cookies.get('token')
        statisticsget(this.aa).then((data) => {
            console.log(data, '统计数据')
            this.fitodat = data.data.data
            this.myCdatData = data.data.data.seven_day_calorimeter_count_list
            this.thCdatData = data.data.data.seven_day_calorimeter_count_date_list
            this.graph()
            console.log(this.myCdatData)
        })
    },
    // 告警数据
    tablData(){
        this.aa.token = Cookies.get('token')
        calorimeterWarning(this.aa).then((data) => {
            console.log(data, '告警数据')
            this.tableData = data.data.data
        })
    },
    // 折线图
    graph(){
        /*初始化ECharts图表，绑定到id=myChart的div上面*/
    var myChart = this.$echarts.init(document.getElementById('myChart'))
    myChart.setOption({
        xAxis: {
       type: 'category',
       data: this.thCdatData
       },
       yAxis: {
           type: 'value'
       },
       series: [{
           data: this.myCdatData,
           type: 'line',
           itemStyle : {
           normal:{color:'#df6124'},
           lineStyle:{color:'#C0353A'}
        }
      }],
	tooltip: {
	trigger: 'axis'
	}
    })
    }
}
}
</script>

<style lang="less" scoped>
.home{
    height: 100%;
}
.Hotop{
    width: 100%;
    // height: 20%;
    display: flex;
    justify-content: space-between;
    .Hotop-did{
        width: 24%;
        height: 100%;
        background-color: #df6124;
        border-radius: 10px;
        .did-feid{
            padding: 20px 20px;
            div{
                line-height: 40px;
                color: #fff;
                font-size: 16px;
                span{
                    color: antiquewhite;
                }
            }
        }
    }
    .Hotop-dis{
        width: 24%;
        height: 100%;
        background-color: #df6124;
        border-radius: 10px;
        .did-feid{
            padding: 20px 20px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            div{
                line-height: 2.6rem;
                color: #fff;
                span{
                    color: antiquewhite;
                }
            }
        }
    }
}
.Hometo{
    width: 100%;
    // height: 75%;
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    .metolef{
        width: 48%;
        .rei-text{
            font-size: 24px;
            color: #df6124;
            font-weight: 400;
            margin-bottom: 12px;
        };
    }
    .metorei{
        width: 48%;
        .rei-text{
            font-size: 24px;
            color: #df6124;
            font-weight: 400;
            margin-bottom: 12px;
        };
    }
}
</style>