import request from "../utils/request";
// 获取项目统计数据
export function statisticsget (data) {
    return request({
      method: 'get',
      url: 'statistics',
      params: data
    })
  }

  // 获取项目统计数据
export function calorimeterWarning (data) {
    return request({
      method: 'get',
      url: 'calorimeterWarning',
      params: data
    })
  }